import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserContext } from "./application/UserContext";
import { useEffect, useState } from "react";
import UserCodePage from "./pages/UserCodePage";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import VerificationCodePage from "./pages/VerificationCodePage";
import NewPasswordPage from "./pages/NewPasswordPage";
import PaymentMethodPage from "./pages/PaymentMethodPage";
import NotFoundPage from "./pages/NotFoundPage";
import Layout from "./pages/Layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ENV } from "./env/env";
import PwaTestPage from "./pages/PwaTest";
import queryString from "query-string";
import AsyncStorage from "@react-native-async-storage/async-storage";
import LinkPermissionPage from "./pages/LinkPermissionPage";
import RegisterPartnerPage from "./pages/PartnerRegisterPage";
import LendInfoPage from "./pages/LendInfoPage";
import ContainerUrlPage from "./pages/ContainerUrlPage";
import SquareOauthPage from "./pages/SquareOauthPage";

const stripePromise = loadStripe(ENV.PUBLIC_STRIPE_KEY);
const options = {
  mode: "setup",
  currency: "eur",
  setupFutureUsage: "off_session",
};
function App() {
  const [user, setUser] = useState({});
  const { redirection, email, name, tab } = queryString.parse(
    window.location.search
  );

  useEffect(() => {
    const saveParams = async () => {
      redirection && AsyncStorage.setItem("redirection", redirection);
      email && AsyncStorage.setItem("email", email);
      name && AsyncStorage.setItem("name", name);
      tab && AsyncStorage.setItem("tab", tab);
    };
    saveParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <BrowserRouter>
        <Layout>
          <Elements stripe={stripePromise} options={options}>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="forgotPassword" element={<ForgotPasswordPage />} />,
              <Route
                path="verificationCode/:email"
                element={<VerificationCodePage />}
              />
              <Route
                path="newPassword/:email/:code"
                element={<NewPasswordPage />}
              />
              ,
              <Route
                path="partner/:partner"
                element={<RegisterPartnerPage />}
              />
              <Route path="paymentMethod" element={<PaymentMethodPage />} />,
              <Route path="userCode" element={<UserCodePage />} />,
              <Route path="linkPermission" element={<LinkPermissionPage />} />,
              <Route path="lend/:containerId" element={<LendInfoPage />} />,
              <Route path="8003/:containerId" element={<ContainerUrlPage />} />,
              <Route path="squareOauth" element={<SquareOauthPage />} />,
              <Route path="test" element={<PwaTestPage />} />,
              <Route path="/*" element={<NotFoundPage />} />,
            </Routes>
          </Elements>
        </Layout>
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </UserContext.Provider>
  );
}

export default App;
