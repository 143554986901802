import { View } from "react-native-web";
import { theme } from "../styles/theme";
import StyledText from "../components/StyledText";
import { t } from "i18next";

const SquareOauthPage = () => {
  /*   useEffect(() => {
    const loadParams = async () => {
      const name = await AsyncStorage.getItem("name");
      const email = await AsyncStorage.getItem("email");
      const tab = await AsyncStorage.getItem("tab");
      email !== "undefined" && setEmail(email);
      name !== "undefined" && setText(name);
      tab !== "undefined" && tab === "register"
        ? setLoginTab(false)
        : setLoginTab(true);
    };
    loadParams();
  }, []); */

  return (
    <View
      style={{
        backgroundColor: theme.colors.white,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          backgroundColor: "#D8E6E0",
          borderRadius: 20,
          width: "70%",
          height: "50%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <View
          style={{
            alignItems: "center",
            gap: theme.spacing.m,
            paddingHorizontal: theme.spacing.xl,
          }}
        >
          <StyledText screenTitleBold color={theme.colors.black} align="center">
            {t("oAuthAcceptedTitle")}
          </StyledText>
          <StyledText
            mainText
            size={theme.sizes.l}
            color={theme.colors.black}
            align="center"
            style={{ flexWrap: "wrap" }}
          >
            {t("oAuthAcceptedText")}
          </StyledText>
        </View>
      </View>
    </View>
  );
};

export default SquareOauthPage;
